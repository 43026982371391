import React from "react"
import { Link } from 'gatsby';

// Component imports
import Layout from "../components/Layout"
import Card from '../components/Card';
import SEO from '../components/SEO';

// Image imports
import mike from '../images/mike-martin-headshot.jpg';

// Routes for URLs
import * as ROUTES from '../constants/routes';

const About = () => {
  return (
    <Layout>
        <SEO 
            title="Meet Mike Martin | Mike Martin Law"
            description="Mike is a Kansas City-based attorney with extensive experience in probate law, estate planning, and trusts."
        />
        <Card>
            <div className="flex flex-col sm:flex-row gap-x-4 w-full items-center border-b border-gray-200 pb-8">
                <img 
                    src={mike}
                    alt="Attorney Mike Martin headshot"
                    className="col-span-4 w-40"
                />
                <h1 className="col-span-8 text-4xl font-bold">Meet Mike Martin</h1>
            </div>
            <div className="grid col-span-1 sm:grid-cols-12 my-8">
                <div className="sm:col-span-8">
                    <p>Mike Martin is a Kansas City-based attorney with extensive experience in probate law, estate planning, and trusts. Mike is also an experienced business attorney. He is licensed to practice law in:</p>
                    <ul>
                        <li>Kansas and Missouri</li>
                        <li>Federal District Court in Kansas</li>
                        <li>United States Tax Court</li>
                    </ul>
                    <p>Born in Tulsa and raised Kansas City, Mike attended business school at the University of Kansas, majoring in accounting and business administration. He went on to earn his law degree at the KU School of Law and practiced in Wichita for two years before coming home to Kansas City to establish Mike Martin Law.</p>
                    <p>Mike prides himself on persistence that delivers results, and he is perhaps best known and most respected by his peers for handling complicated <Link to={ROUTES.PROBATE} className="text-secondary">probate</Link> cases.</p>
                    <p>Mike’s passion for probate law – and for helping clients with their <Link to={ROUTES.ESTATES} className="text-secondary">estate planning</Link> needs so that they can prevent probate problems – was sparked by personal experience.</p>
                    <p>In 1989, as Mike prepared to launch his own law practice in Kansas City, he was named the executor of his grandfather’s estate. Aspects of his grandfather’s estate were disputed, and the estate was embroiled in litigation for more than a year.</p>
                    <p>This eye-opening experience in probate court had a profound impact on Mike’s burgeoning law career. He soon began receiving referrals from a probate attorney who had been involved in his grandfather’s case, and he was appointed to a number of probate cases.</p>
                    <p>His growing expertise in probate gradually expanded into the related areas of estate planning and trusts.</p>
                    <p>Two decades later, Mike maintains his passion for probate law. He focus on handling adversarial cases, and he receives referrals from estate planning attorneys across the region whose clients need an experienced litigator.</p>
                    <p>As an estate planning attorney, he has experience helping clients with every aspect of end-of-life panning, from basic wills and trusts to situations that require sophisticated planning, such as domestic partnerships, planning for the care of a child with disabilities, or providing for pets after death.</p>
                    <p>Mike also has established a thriving <Link to={ROUTES.BUSINESS} className="text-secondary">business law practice</Link>, which grew naturally out of his business training and background. Mike earned a bachelor’s degree in accounting and business administration at KU’s School of Business prior to attending law school, and he spent his first two years in practice at a tax and securities law firm in Wichita.</p>
                    <p>Mike’s combination of practice areas often allows him to better represent the interests of his clients. For instance, his knowledge of business law proves valuable when business holdings are part of a probate case – and his experience in probate court informs his estate planning work.</p>
                    <p>In addition, Mike’s clients benefit from his broad experience in court. Many attorneys who practice probate, estate planning, and business law work primarily procedural lawyers who draw up and review contracts and other legal documents.</p>
                    <p>In addition to handling these types of procedural needs, Mike also has significant courtroom experience, including business and construction arbitration; mediation in civil, probate and trust matters; civil trials including jury trials; and hearings before administrative bodies in Kansas and Missouri, including the Kansas Board of Accountancy, Kansas Board of Healing Arts, Kansas Board of Realtors, Kansas Corporation Commission, and Board of Tax Appeals.</p>
                    <p>Mike is a member of the Johnson County Bar Association and a volunteer with the Kansas Lawyer’s Assistance Program (KLAP), which was established by the Kansas Supreme Court in 2001 to protect clients, assist lawyers, and educate judges and lawyers.</p>
                    <p>Recent professional highlights include serving as committee chair and seminar presenter for Planning Ahead, Incapacity, Disability or Absence of a Solo Practitioner in May 2010, and presenting Ethics of Professional Fees in Probate Matters in April 2011, both for the Johnson County Bar Association.</p>
                    <h3 className="section-header">Education</h3>
                    <ul>
                        <li>University of Kansas School of Law, J.D. (1985)</li>
                        <li>University of Kansas, School of Business, B.S. in Accounting and Business Administration (1982)</li>
                    </ul>
                </div>
            </div>
        </Card>
    </Layout>
  )
}

export default About;